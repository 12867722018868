import React from 'react';

const AttentionTestResults = ({ data }) => (
  <table>
    <thead>
      <tr>
        <th>Metric</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Average Response Time</td>
        <td>{data.averageResponseTime.toFixed(2)} ms</td>
      </tr>
      <tr>
        <td>Accuracy</td>
        <td>{(data.accuracy * 100).toFixed(2)}%</td>
      </tr>
    </tbody>
  </table>
);

export default AttentionTestResults;
