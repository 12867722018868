import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { getCogniUser, updateCogniUser } from '../../services/cogniUsersService';
import './Profile.css';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const cogniUserData = await getCogniUser(currentUser.uid);
          setUserData(cogniUserData);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load user data. Please try again later.');
        }
      }
    };
    fetchUserData();
  }, [currentUser]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    if (userData) {
      try {
        await updateCogniUser(currentUser.uid, {
          firstName: userData.firstName,
          lastName: userData.lastName,
        });
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating user data:', error);
        setError('Failed to update profile. Please try again later.');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to log out. Please try again.');
    }
  };

  if (error) return <div className="error-message">{error}</div>;
  if (!userData) return <div>Loading...</div>;

  return (
    <div className="profile-container">
      <div className="profile-content">
        <h2>User Profile</h2>
        {isEditing ? (
          <>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={userData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={userData.lastName}
                onChange={handleChange}
              />
            </div>
            <button onClick={handleSave} className="btn-save">Save</button>
          </>
        ) : (
          <>
            <p><strong>Name:</strong> {userData.firstName} {userData.lastName}</p>
            <p><strong>Email:</strong> {userData.email}</p>
            <button onClick={handleEdit} className="btn-edit">Edit Profile</button>
          </>
        )}
      </div>
      <div className="profile-footer">
        <button onClick={handleLogout} className="btn-logout">Logout</button>
      </div>
    </div>
  );
};

export default Profile;