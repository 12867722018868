import React, { useState } from 'react';
import { useUserManagement } from '../../hooks/useUserManagement';
import UserTable from './UserTable';
import CreateUserForm from './CreateUserForm';

const UserManagement = () => {
  const { users, error, loading, handleRoleChange, handleCreateUser } = useUserManagement();
  const [showCreateForm, setShowCreateForm] = useState(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="user-management">
      <div className="user-management-header">
        <h3>User Management</h3>
        <button 
          className={`admin-button ${showCreateForm ? 'cancel' : 'create'}`}
          onClick={() => setShowCreateForm(!showCreateForm)}
        >
          {showCreateForm ? 'Cancel' : 'Create New User'}
        </button>
      </div>
      {showCreateForm && <CreateUserForm onUserCreated={() => {
        handleCreateUser();
        setShowCreateForm(false);
      }} />}
      <UserTable users={users} onRoleChange={handleRoleChange} />
    </div>
  );
};

export default UserManagement;
