import React from 'react';

const StroopTestResults = ({ data }) => {
  const trials = [
    'switch, word',
    'noswitch, word',
    'switch, color',
    'noswitch, color'
  ];

  return (
    <div className="stroop-test-results">
      <h4>Stroop Test Results</h4>
      <table>
        <thead>
          <tr>
            <th>Trial</th>
            <th>Avg. rxn. time (ms)</th>
            <th>Error rate</th>
          </tr>
        </thead>
        <tbody>
          {trials.map(trial => (
            <tr key={trial}>
              <td>{trial}</td>
              <td>{data[trial] ? data[trial].avgReactionTime.toFixed(2) : 'N/A'}</td>
              <td>{data[trial] ? (data[trial].errorRate * 100).toFixed(2) + '%' : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StroopTestResults;
