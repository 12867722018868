import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #f8f9fa;
  padding: 2rem 0;
  border-top: 1px solid #e9ecef;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Copyright = styled.p`
  color: #6c757d;
  font-size: 0.9rem;
  margin: 0;
`;

const Links = styled.nav`
  display: flex;
  gap: 1.5rem;
  
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const Link = styled.a`
  color: #6c757d;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease-in-out;
  
  &:hover {
    color: #007bff;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <Copyright>© {new Date().getFullYear()} Cogneval. All rights reserved.</Copyright>
        <Links>
          <Link href="/privacy">Privacy Policy</Link>
          <Link href="/terms">Terms of Service</Link>
          <Link href="/contact">Contact Us</Link>
        </Links>
      </FooterContent>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  // Add prop types here if needed
};

export default Footer;
