import { db, auth } from '../../../../firebase';
import { doc, setDoc, collection, addDoc } from 'firebase/firestore';
import { MAX_SAMPLE_TRIALS, MAX_PRACTICE_TRIALS, MAX_TEST_TRIALS } from './StroopTest';
import { logError } from '../../../../services/errorLoggingService';
import { calculateOverallScore, calculateAverageReactionTime, calculateColorNamingAccuracy, calculateWordReadingAccuracy, calculateInterferenceEffect } from './utils/calculationUtils';

export const generateMockData = async (sessionId, testType) => {
  try {
    const mockData = [];
    const phases = ['sample', 'practice', 'test'];
    const cueWords = ['Color', 'Word'];
    const stimulusWords = ['RED', 'GREEN', 'BLUE'];
    const stimulusColors = ['red', 'green', 'blue'];

    const startTime = new Date().toISOString();

    for (let phase of phases) {
      const maxTrials = phase === 'sample' ? MAX_SAMPLE_TRIALS : phase === 'practice' ? MAX_PRACTICE_TRIALS : MAX_TEST_TRIALS;
      let currentCueIndex = 0;

      for (let trialNumber = 0; trialNumber < maxTrials; trialNumber++) {
        const cueWord = cueWords[currentCueIndex];
        const stimulusWord = stimulusWords[Math.floor(Math.random() * stimulusWords.length)];
        const stimulusColor = stimulusColors[Math.floor(Math.random() * stimulusColors.length)];
        const isCongruent = stimulusWord === stimulusColor.toUpperCase();
        const isSwitch = trialNumber % 2 === 0;

        let reactionTime, isCorrect;

        if (isCongruent) {
          reactionTime = Math.floor(Math.random() * 300) + 500; // 500-800ms
          isCorrect = Math.random() < 0.95; // 95% accuracy
        } else {
          reactionTime = Math.floor(Math.random() * 400) + 600; // 600-1000ms
          isCorrect = Math.random() < 0.85; // 85% accuracy
        }

        if (isSwitch) {
          reactionTime += Math.floor(Math.random() * 100); // Add 0-100ms for switch cost
          isCorrect = isCorrect && (Math.random() < 0.95); // Slightly lower accuracy for switch trials
        }

        const response = isCorrect ? (cueWord === 'Color' ? stimulusColor[0] : stimulusWord[0]) : 'X';

        const trialData = {
          trialNumber,
          phase,
          cueWord,
          stimulusWord,
          stimulusColor,
          response,
          reactionTime,
          isCorrect,
          isSwitch,
          isCongruent,
          startTime: new Date().toISOString(),
          endTime: new Date(new Date().getTime() + reactionTime).toISOString(),
        };

        mockData.push(trialData);

        // Switch cue every two trials
        if (trialNumber % 2 !== 0) {
          currentCueIndex = (currentCueIndex + 1) % 2;
        }
      }
    }

    const endTime = new Date().toISOString();

    const testResult = {
      userId: auth.currentUser.uid,
      sessionId,
      testType,
      startTime,
      endTime,
      overallScore: calculateOverallScore(mockData),
      commonMetrics: {
        totalTrials: mockData.length,
        correctTrials: mockData.filter(trial => trial.isCorrect).length,
        averageReactionTime: calculateAverageReactionTime(mockData)
      },
      stroopTestData: {
        colorNamingAccuracy: calculateColorNamingAccuracy(mockData),
        wordReadingAccuracy: calculateWordReadingAccuracy(mockData),
        interferenceEffect: calculateInterferenceEffect(mockData)
      }
    };

    const testResultId = `mockTest_${Date.now()}`;
    await setDoc(doc(db, 'cogniTestResults', testResultId), testResult);

    for (const trialData of mockData) {
      await addDoc(collection(db, 'cogniTestResults', testResultId, 'trials'), trialData);
    }

    return { testResultId, mockData };
  } catch (error) {
    await logError('MOCK_DATA_GENERATION_ERROR', error.message, auth.currentUser.uid, { sessionId, testType });
    throw error;
  }
};

