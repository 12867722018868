// components/home/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';
import { ReactComponent as TestsIcon } from '../assets/images/tests-icon.svg';
import { ReactComponent as TrainingIcon } from '../assets/images/training-icon.svg';
import staticText from '../config/staticText.json';


const HomePage = () => {
  const { heroTitle, heroSubtitle, ctaButton } = staticText.homePage;

  return (
    <div className="home-page">
      <section className="hero">
        <div className="hero-content">
          <h1 className="hero-title">{heroTitle}</h1>
          <p className="hero-subtitle">{heroSubtitle}</p>
          <Link to="/register" className="cta-button primary">{ctaButton}</Link>
        </div>
      </section>

      <section className="features">
        <div className="feature">
          <TestsIcon className="feature-icon" />
          <h3 className="feature-title">Comprehensive Cognitive Tests</h3>
          <p className="feature-description">
            Explore our wide range of scientifically validated cognitive tests, including Stroop Test, N-back, Tower of Hanoi, and more.
            Gain deep insights into your cognitive strengths and areas for improvement. Track your progress over time and compare your performance with others.
          </p>
          <Link to="/cognitive-tests" className="cta-button secondary">Discover Tests</Link>
        </div>

        <div className="feature">
          <TrainingIcon className="feature-icon" />
          <h3 className="feature-title">Personalized Training Exercises</h3>
          <p className="feature-description">
            Engage in tailored training exercises designed to target specific cognitive domains. Enhance your memory, attention, problem-solving skills, and mental agility.
            Experience adaptive difficulty levels that challenge you at the right pace.
          </p>
          <Link to="/training-exercises" className="cta-button secondary">Start Training</Link>
        </div>

        {/* Add more feature sections */}
      </section>

      {/* <section className="testimonials">
        <h2 className="section-title">What Our Users Say</h2>
        <div className="testimonial">
          <img src="/path/to/user1-avatar.jpg" alt="User Avatar" className="testimonial-avatar" />
          <blockquote className="testimonial-quote">
            "Synaptoscopics has revolutionized the way I approach cognitive enhancement. The personalized exercises and insightful progress tracking have helped me unlock my true potential."
          </blockquote>
          <cite className="testimonial-author">John Doe</cite>
        </div>

      </section> */}

      <section className="cta">
        <h2 className="cta-title">Ready to Enhance Your Cognitive Abilities?</h2>
        <p className="cta-description">
          Join Synaptoscopics today and embark on a transformative journey of cognitive optimization.
        </p>
        <Link to="/register" className="cta-button primary">Get Started</Link>
      </section>
    </div>
  );
};

export default HomePage;