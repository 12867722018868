import { db, auth } from '../firebase';
import { doc, updateDoc, deleteDoc, collection, getDocs, getDoc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';

export const updateUser = async (userId, userData) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, userData);
};

export const deleteUser = async (userId) => {
  const userRef = doc(db, 'users', userId);
  await deleteDoc(userRef);
};

export const getAllUsers = async () => {
  try {
    const usersCollection = collection(db, 'users');
    const userSnapshot = await getDocs(usersCollection);
    return userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error getting users:', error);
    throw error;
  }
};

export const generateSystemWideReport = async () => {
  // Implement your report generation logic here
  // This could involve querying multiple collections and aggregating data
};

export const setUserRole = async (userId, role) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { role });
};

export const getUserRole = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    return userSnap.data().role;
  }
  return null;
};

export const checkAdminStatus = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    const userData = userSnap.data();
    console.log(`User ${userId} role:`, userData.role);
    return userData.role === 'admin';
  }
  console.log(`User ${userId} not found`);
  return false;
};

export const createNewUser = async (email, password, firstName, lastName, role = 'user') => {
  try {
    // Create the user in Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const userId = userCredential.user.uid;

    // Create the user document in Firestore
    await setDoc(doc(db, 'users', userId), {
      email,
      firstName,
      lastName,
      role,
      createdAt: new Date().toISOString(),
      lastLogin: new Date().toISOString(),
      isActive: true
    });

    return userId;
  } catch (error) {
    console.error('Error creating new user:', error);
    throw error;
  }
};

export const getAllUserData = async () => {
  const usersCollection = collection(db, 'users');
  const userSnapshot = await getDocs(usersCollection);
  return userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const getAllTestResults = async () => {
  try {
    const stroopResults = await getStroopTestReport();
    const memoryResults = await getMemoryTestReport();
    const attentionResults = await getAttentionTestReport();

    return {
      stroop: stroopResults,
      memory: memoryResults,
      attention: attentionResults
    };
  } catch (error) {
    console.error('Error getting all test results:', error);
    throw error;
  }
};

// Implement these functions similarly to getStroopTestReport
const getMemoryTestReport = async () => {
  // Fetch and process memory test results
};

const getAttentionTestReport = async () => {
  // Fetch and process attention test results
};

export const getStroopTestReport = async () => {
  try {
    const stroopResultsCollection = collection(db, 'stroopTestResults');
    const stroopSnapshot = await getDocs(stroopResultsCollection);
    const allResults = stroopSnapshot.docs.map(doc => doc.data());

    const categories = {
      'switch, word': { totalTime: 0, totalTrials: 0, errors: 0 },
      'noswitch, word': { totalTime: 0, totalTrials: 0, errors: 0 },
      'switch, color': { totalTime: 0, totalTrials: 0, errors: 0 },
      'noswitch, color': { totalTime: 0, totalTrials: 0, errors: 0 }
    };

    allResults.forEach(result => {
      result.trials.forEach(trial => {
        const category = `${trial.isSwitch ? 'switch' : 'noswitch'}, ${trial.stimulusType}`;
        categories[category].totalTime += trial.reactionTime;
        categories[category].totalTrials++;
        if (!trial.isCorrect) {
          categories[category].errors++;
        }
      });
    });

    const report = Object.entries(categories).reduce((acc, [key, value]) => {
      acc[key] = {
        avgReactionTime: value.totalTrials > 0 ? value.totalTime / value.totalTrials : 0,
        errorRate: value.totalTrials > 0 ? value.errors / value.totalTrials : 0
      };
      return acc;
    }, {});

    return report;
  } catch (error) {
    console.error('Error getting Stroop test report:', error);
    throw error;
  }
};
