import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>Last updated: [Current Date]</p>
      
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using Synaptoscopics, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access the service.</p>
      </section>

      <section>
        <h2>2. Description of Service</h2>
        <p>Synaptoscopics provides cognitive training exercises and assessments. We reserve the right to modify or discontinue, temporarily or permanently, the service with or without notice.</p>
      </section>

      <section>
        <h2>3. User Accounts</h2>
        <p>You are responsible for safeguarding the password you use to access the service and for any activities or actions under your password. You must immediately notify us of any unauthorized use of your account.</p>
      </section>

      <section>
        <h2>4. Intellectual Property</h2>
        <p>The service and its original content, features, and functionality are owned by Synaptoscopics and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.</p>
      </section>

      <section>
        <h2>5. User Content</h2>
        <p>You retain any and all of your rights to any content you submit, post or display on or through the service and you are responsible for protecting those rights.</p>
      </section>

      <section>
        <h2>6. Limitation of Liability</h2>
        <p>In no event shall Synaptoscopics, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.</p>
      </section>

      <section>
        <h2>7. Governing Law</h2>
        <p>These Terms shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.</p>
      </section>

      <section>
        <h2>8. Changes to Terms</h2>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any significant changes to these Terms.</p>
      </section>

      <section>
        <h2>9. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at: [Contact Email]</p>
      </section>
    </div>
  );
};

export default TermsOfService;
