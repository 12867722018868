import { db } from '../firebase';
import { collection, addDoc, doc, getDoc, query, where, orderBy, limit, getDocs } from 'firebase/firestore';

export const createCogniTestResult = async (userId, sessionId, testType, resultData) => {
  const cogniTestResultsCollection = collection(db, 'cogniTestResults');
  const newTestResult = {
    userId,
    sessionId,
    testType,
    startTime: resultData.startTime,
    endTime: resultData.endTime,
    overallScore: resultData.overallScore,
    commonMetrics: resultData.commonMetrics,
    [testType + 'Data']: resultData.testSpecificData
  };
  const docRef = await addDoc(cogniTestResultsCollection, newTestResult);
  return docRef.id;
};

export const getCogniTestResult = async (resultId) => {
  const resultDoc = await getDoc(doc(db, 'cogniTestResults', resultId));
  if (resultDoc.exists()) {
    return { id: resultDoc.id, ...resultDoc.data() };
  }
  return null;
};

const formatDate = (dateField) => {
  if (dateField && typeof dateField.toDate === 'function') {
    // It's a Firestore Timestamp
    return dateField.toDate().toISOString();
  } else if (dateField && dateField.seconds) {
    // It's a Firestore Timestamp in seconds
    return new Date(dateField.seconds * 1000).toISOString();
  } else if (dateField && typeof dateField === 'string') {
    // It's already a string, assume it's in a valid date format
    return dateField;
  } else if (dateField && typeof dateField.getTime === 'function') {
    // It's a JavaScript Date object
    return dateField.toISOString();
  }
  // If it's none of the above, return null or some default value
  return null;
};

export const getRecentCogniTestResults = async (userId, limitCount = 10) => {
  console.log('Fetching recent test results for user:', userId);
  const cogniTestResultsCollection = collection(db, 'cogniTestResults');
  const q = query(
    cogniTestResultsCollection,
    where('userId', '==', userId),
    orderBy('startTime', 'desc'),
    limit(limitCount)
  );
  const snapshot = await getDocs(q);
  const results = await Promise.all(snapshot.docs.map(async (doc) => {
    const data = doc.data();
    console.log('Raw document data:', data);  // Log raw data for debugging

    // Fetch trials for this test result
    const trialsRef = collection(db, 'cogniTestResults', doc.id, 'trials');
    const trialsSnapshot = await getDocs(trialsRef);
    const trials = trialsSnapshot.docs.map(trialDoc => ({
      id: trialDoc.id,
      ...trialDoc.data()
    }));

    return {
      id: doc.id,
      ...data,
      startTime: formatDate(data.startTime),
      endTime: formatDate(data.endTime),
      trials: trials
    };
  }));

  console.log('Processed test results:', results);
  return results;
};

const getAllStroopTestData = async () => {
  try {
    const cogniTestResultsRef = collection(db, 'cogniTestResults');
    const q = query(cogniTestResultsRef, where('testType', '==', 'stroopTest'));
    const querySnapshot = await getDocs(q);
    
    const stroopTestData = [];
    for (const doc of querySnapshot.docs) {
      const testResult = doc.data();
      testResult.id = doc.id;

      // Fetch trials for this test result
      const trialsRef = collection(db, 'cogniTestResults', doc.id, 'trials');
      const trialsSnapshot = await getDocs(trialsRef);
      const trials = trialsSnapshot.docs.map(trialDoc => ({
        id: trialDoc.id,
        ...trialDoc.data()
      }));

      stroopTestData.push({
        ...testResult,
        trials
      });
    }

    return stroopTestData;
  } catch (error) {
    console.error("Error fetching Stroop test data:", error);
    throw error;
  }
};