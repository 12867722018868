import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, addDoc, doc, setDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB43ZFNzJ1WEgO0vLzk6n2SHndSJrQAcqE",
    authDomain: "synapsescope.firebaseapp.com",
    databaseURL: "https://synapsescope-default-rtdb.firebaseio.com",
    projectId: "synapsescope",
    storageBucket: "synapsescope.appspot.com",
    messagingSenderId: "737633710139",
    appId: "1:737633710139:web:5b6471ba26a808fcf9ec1e",
    measurementId: "G-K5MMJTB7MS"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);

export const initializeTest = async (sessionId, testType) => {
  console.log('Initializing new test:', { sessionId, testType });
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user found');
      throw new Error('No authenticated user found');
    }
    console.log('Authenticated user:', user.uid);
    const userId = user.uid;
    
    const testResultsRef = collection(db, 'cogniTestResults');
    
    const newTestResult = {
      userId,
      sessionId,
      testType,
      startTime: new Date().toISOString(),
    };
    const docRef = await addDoc(testResultsRef, newTestResult);
    console.log('Created new test result with ID:', docRef.id);
    return docRef.id;
  } catch (e) {
    console.error('Error initializing test: ', e);
    throw e;
  }
};

export const addTrialData = async (testResultId, trialData) => {
  console.log('Adding trial data:', { testResultId, trialData });
  try {
    const trialDocRef = await addDoc(collection(db, 'cogniTestResults', testResultId, 'trials'), trialData);
    console.log('Trial data added successfully with ID:', trialDocRef.id);
  } catch (e) {
    console.error('Error adding trial data: ', e);
    throw e;
  }
};

export const writeTrialData = async (sessionId, testType, trialData) => {
  console.log('Attempting to write trial data:', { sessionId, testType, trialData });
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user found');
      throw new Error('No authenticated user found');
    }
    console.log('Authenticated user:', user.uid);
    const userId = user.uid;
    
    const testResultsRef = collection(db, 'cogniTestResults');
    
    console.log('Creating new test result');
    const newTestResult = {
      userId,
      sessionId,
      testType,
      startTime: new Date().toISOString(),
    };
    const docRef = await addDoc(testResultsRef, newTestResult);
    const testResultId = docRef.id;
    console.log('Created new test result with ID:', testResultId);
    
    console.log('Adding trial data to subcollection');
    const trialDocRef = await addDoc(collection(db, 'cogniTestResults', testResultId, 'trials'), trialData);
    console.log('Trial data written successfully with ID:', trialDocRef.id);
  } catch (e) {
    console.error('Error writing trial data: ', e);
    throw e;
  }
};

export const writeTestResults = async (sessionId, testType, testResults) => {
  console.log('Attempting to write test results:', { sessionId, testType, testResults });
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user found');
      throw new Error('No authenticated user found');
    }
    console.log('Authenticated user:', user.uid);
    const userId = user.uid;

    const docRef = doc(db, 'cogniTestResults', `${userId}_${sessionId}_${testType}`);
    console.log('Writing test results to document:', docRef.path);
    await setDoc(docRef, { ...testResults, userId }, { merge: true });
    console.log('Test results written successfully');
  } catch (error) {
    console.error('Error writing test results: ', error);
    throw error;
  }
};
