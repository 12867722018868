// components/common/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/images/logo.svg';
import userAvatar from '../../assets/images/user-avatar.png';
import { useAuth } from '../../contexts/AuthContext';

const Header = () => {
  const { isLoggedIn, currentUser } = useAuth();

  return (
    <header className="header">
      <Link to="/" className="logo-container">
        <img src={logo} alt="Logo" className="logo-image" />
        <h1 className="logo-text">Cogneval</h1>
      </Link>
      <div className="user-profile">
        {isLoggedIn ? (
          <Link to="/profile">
            <img src={userAvatar} alt="User Avatar" className="user-avatar" />
          </Link>
        ) : (
          <div className="auth-links">
            <Link to="/login" className="auth-link-login">Login</Link>
            <Link to="/register" className="auth-link-register">Register</Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
