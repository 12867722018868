import React, { useState } from 'react';
import { generateMockData } from './mockDataUtils'; // Assume we've moved the generateMockData function to a separate file
import Modal from './Modal';
import styles from './MockDataGenerator.module.css';

const MockDataGenerator = ({ sessionId, testType }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleGenerateData = async () => {
    if (isGenerating) return; // Prevent multiple simultaneous generations

    setIsGenerating(true);
    setError(null);

    try {
      await generateMockData(sessionId, testType);
      setIsModalOpen(true);
    } catch (err) {
      console.error('Error generating mock data:', err);
      setError('An error occurred while generating mock data.');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <>
      <button 
        onClick={handleGenerateData} 
        disabled={isGenerating}
        className={`${styles.button} ${styles.generateButton} ${isGenerating ? styles.generating : ''}`}
      >
        {isGenerating ? 'Generating...' : 'Generate Mock Data'}
      </button>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        Mock data generated and saved to Firebase.
      </Modal>
    </>
  );
};

export default MockDataGenerator;