import { auth } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { createCogniUser, updateLastLogin } from './cogniUsersService';
import { logError } from './errorLoggingService'; // Add this line

// Add service methods and logic here

export const registerUser = async (email, password, firstName, lastName) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await createCogniUser(userCredential.user.uid, { firstName, lastName, email });
  return userCredential.user;
};

export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    await updateLastLogin(userCredential.user.uid);
    return userCredential.user;
  } catch (error) {
    await logError('LOGIN_ERROR', error.message, null, { email });
    throw error;
  }
};

export const logoutUser = () => signOut(auth);
