import React from 'react';

const StroopTestResult = ({ result }) => {
  const getValue = (key, defaultValue = 'N/A') => {
    const value = key.split('.').reduce((obj, k) => obj && obj[k], result);
    return value !== undefined ? value : defaultValue;
  };

  const formatValue = (value, isPercentage = false) => {
    if (typeof value === 'number') {
      return isPercentage ? `${(value * 100).toFixed(2)}%` : value.toFixed(2);
    }
    return value;
  };

  const calculateTrialData = (trials) => {
    console.log('Calculating trial data for:', trials);

    const categories = {
      'switch, word': { totalTime: 0, count: 0, errors: 0 },
      'noswitch, word': { totalTime: 0, count: 0, errors: 0 },
      'switch, color': { totalTime: 0, count: 0, errors: 0 },
      'noswitch, color': { totalTime: 0, count: 0, errors: 0 }
    };

    trials.forEach(trial => {
      if (trial.phase === "test") {
        const category = `${trial.isSwitch ? 'switch' : 'noswitch'}, ${trial.cueWord.toLowerCase()}`;
        console.log('Processing test trial:', trial);
        console.log('Categorized as:', category);
        if (categories[category]) {
          categories[category].totalTime += trial.reactionTime;
          categories[category].count++;
          if (!trial.isCorrect) {
            categories[category].errors++;
          }
          console.log('Updated category data:', categories[category]);
        } else {
          console.warn('Unexpected category:', category);
        }
      } else {
        console.log('Skipping non-test trial:', trial);
      }
    });

    console.log('Final categories data:', categories);

    const result = Object.entries(categories).map(([key, value]) => ({
      trial: key,
      avgReactionTime: value.count > 0 ? value.totalTime / value.count : 0,
      errorRate: value.count > 0 ? value.errors / value.count : 0
    }));

    console.log('Calculated trial data:', result);
    return result;
  };

  const trialData = calculateTrialData(result.trials || []);

  return (
    <div className="stroop-details">
      {/* Existing metrics */}
      <div className="stroop-metrics">
        <div className="metric">
          <span className="metric-label">Date:</span>
          <span className="metric-value">{new Date(getValue('startTime')).toLocaleString()}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Overall Score:</span>
          <span className="metric-value">{getValue('overallScore')}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Average Reaction Time:</span>
          <span className="metric-value">{formatValue(getValue('commonMetrics.averageReactionTime'))} ms</span>
        </div>
        <div className="metric">
          <span className="metric-label">Correct Trials:</span>
          <span className="metric-value">{getValue('commonMetrics.correctTrials')}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Total Trials:</span>
          <span className="metric-value">{getValue('commonMetrics.totalTrials')}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Color Naming Accuracy:</span>
          <span className="metric-value">{formatValue(getValue('stroopTestData.colorNamingAccuracy'), true)}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Word Reading Accuracy:</span>
          <span className="metric-value">{formatValue(getValue('stroopTestData.wordReadingAccuracy'), true)}</span>
        </div>
        <div className="metric">
          <span className="metric-label">Start Time:</span>
          <span className="metric-value">{new Date(getValue('startTime')).toLocaleString()}</span>
        </div>
        <div className="metric">
          <span className="metric-label">End Time:</span>
          <span className="metric-value">{new Date(getValue('endTime')).toLocaleString()}</span>
        </div>
      </div>

      {/* New trial data table */}
      <div className="stroop-trial-data">
        <h4>Trial Data</h4>
        <table>
          <thead>
            <tr>
              <th>Trial</th>
              <th>Avg. rxn. time (ms)</th>
              <th>Error rate</th>
            </tr>
          </thead>
          <tbody>
            {trialData.map(({ trial, avgReactionTime, errorRate }) => (
              <tr key={trial}>
                <td>{trial}</td>
                <td>{formatValue(avgReactionTime)}</td>
                <td>{formatValue(errorRate, true)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="debug-info">
        <small>Test ID: {getValue('id')}</small>
        <br />
        <small>Session ID: {getValue('sessionId')}</small>
      </div>
    </div>
  );
};

export default StroopTestResult;
