// components/cognitive-tests/CognitiveTests.js
import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TestCard from './TestCard';
import './CognitiveTests.css';
import testDetails from '../../config/testDetails.json';
import { useAuth } from '../../contexts/AuthContext'; // Assuming you have this hook

const CognitiveTests = () => {
  console.log('CognitiveTests component initialized');
  const navigate = useNavigate();
  const { currentUser, loading } = useAuth(); // Add this line to get auth state

  useEffect(() => {
    console.log('CognitiveTests useEffect triggered');
    console.log('Current user:', currentUser);
    console.log('Loading:', loading);
  }, [currentUser, loading]);

  const handleTestClick = (testPath) => {
    console.log(`Test clicked: ${testPath}`);
    navigate(testPath);
  };

  console.log('Rendering CognitiveTests component');
  console.log('Test details:', testDetails);

  return (
    <div className="cognitive-tests">
      <h2 className="cognitive-tests-title">Cognitive Tests</h2>
      <p className="cognitive-tests-description">
        Explore our comprehensive suite of cognitive tests designed to assess various aspects of your cognitive abilities.
        From attention and memory to problem-solving and reaction time, our tests provide valuable insights into your brain's performance.
        Select a test below to begin your cognitive assessment journey.
      </p>
      <div className="test-card-container">
        {testDetails.tests.map((test) => {
          console.log(`Rendering test card for: ${test.title}`);
          return (
            <TestCard
              key={test.path}
              title={test.title}
              description={test.description}
              onClick={() => handleTestClick(test.path)}
              isDisabled={test.isDisabled}
            />
          );
        })}
      </div>
      <Link 
        to="/cognitive-tests/stroop-test" 
        onClick={() => {
          console.log('Stroop Test link clicked');
          navigate('/cognitive-tests/stroop-test');
        }}
      >
        Stroop Test
      </Link>
    </div>
  );
};

export default CognitiveTests;
