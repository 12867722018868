// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/common/Header';
import Sidebar from './components/common/Sidebar';
import Footer from './components/common/Footer'; // Add this import
import HomePage from './components/HomePage';
import CognitiveTests from './components/cognitive-tests/CognitiveTests';
import TrainingExercises from './components/training-exercises/TrainingExercises';
import ProgressTracking from './components/progress-tracking/ProgressTracking';
import EducationalContent from './components/educational-content/EducationalContent';
import SocialInteraction from './components/social-interaction/SocialInteraction';
import Profile from './components/profile/Profile';
import LoginForm from './components/auth/LoginForm';
import RegistrationForm from './components/auth/RegistrationForm';
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import AdminDashboard from './components/admin/AdminDashboard';
import { AuthProvider } from './contexts/AuthContext';
import StroopTest from './components/cognitive-tests/stroop-test/StroopTest'; // Add this import
import PrivacyPolicy from './components/PrivacyPolicy'; // Add this import
import TermsOfService from './components/TermsOfService'; // Add this import
import './App.css';

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <AuthProvider>
      <Router>
        <div className="app">
          <Header />
          <div className={`app-container ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
            <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
            <main className={`main-content ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/cognitive-tests" element={<CognitiveTests />} />
                <Route path="/training-exercises" element={<TrainingExercises />} />
                <Route path="/progress-tracking" element={
                  <PrivateRoute>
                    <ProgressTracking />
                  </PrivateRoute>
                } />
                <Route path="/educational-content" element={<EducationalContent />} />
                <Route path="/social-interaction" element={<SocialInteraction />} />
                <Route path="/profile" element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                } />
                <Route path="/login" element={<LoginForm />} />
                <Route path="/register" element={<RegistrationForm />} />
                <Route path="/privacy" element={<PrivacyPolicy />} /> {/* Add this line */}
                <Route path="/terms" element={<TermsOfService />} /> {/* Add this line */}
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute>
                      <AdminRoute>
                        <AdminDashboard />
                      </AdminRoute>
                    </PrivateRoute>
                  }
                />
                <Route 
                  path="/cognitive-tests/stroop-test" 
                  element={
                    <PrivateRoute>
                      <StroopTest />
                    </PrivateRoute>
                  } 
                />
              </Routes>
            </main>
          </div>
          <Footer /> {/* Add this line */}
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;