import React, { useState, useEffect } from 'react';
import { getAllTestResults } from '../../services/adminService';
import StroopTestResults from './testResults/StroopTestResults';
import MemoryTestResults from './testResults/MemoryTestResults';
import AttentionTestResults from './testResults/AttentionTestResults';

const TestResultsView = () => {
  const [testResults, setTestResults] = useState({});
  const [selectedTest, setSelectedTest] = useState('stroop');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTestResults = async () => {
      try {
        const results = await getAllTestResults();
        setTestResults(results);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchTestResults();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const renderTestResults = () => {
    switch (selectedTest) {
      case 'stroop':
        return <StroopTestResults data={testResults.stroop} />;
      case 'memory':
        return <MemoryTestResults data={testResults.memory} />;
      case 'attention':
        return <AttentionTestResults data={testResults.attention} />;
      default:
        return <div>Select a test type</div>;
    }
  };

  return (
    <div className="test-results-view">
      <h3>Test Results</h3>
      <select 
        value={selectedTest} 
        onChange={(e) => setSelectedTest(e.target.value)}
        className="test-select"
      >
        <option value="stroop">Stroop Test</option>
        <option value="memory">Memory Test</option>
        <option value="attention">Attention Test</option>
      </select>
      {renderTestResults()}
    </div>
  );
};

export default TestResultsView;
