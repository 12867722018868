import React from 'react';
import './TestCard.css';

const TestCard = ({ title, description, onClick, isDisabled }) => {
 return (
   <div 
     className={`test-card ${isDisabled ? 'disabled' : ''}`} 
     onClick={isDisabled ? null : onClick}
   >
     <h3 className="test-card-title">{title}</h3>
     <p className="test-card-description">{description}</p>
   </div>
 );
};

export default TestCard;
