import React from 'react';

const UserTable = ({ users, onRoleChange }) => (
  <table>
    <thead>
      <tr>
        <th>Email</th>
        <th>Name</th>
        <th>Role</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {users.map(user => (
        <tr key={user.id}>
          <td>{user.email}</td>
          <td>{user.firstName} {user.lastName}</td>
          <td>{user.role}</td>
          <td>
            <select
              value={user.role}
              onChange={(e) => onRoleChange(user.id, e.target.value)}
              className="role-select"
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default UserTable;
