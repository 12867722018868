// components/common/Sidebar.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { useAuth } from '../../contexts/AuthContext';
import { auth } from '../../firebase';

const Sidebar = ({ isOpen, setIsOpen }) => {
  const { isLoggedIn, logout, isAdmin } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => setIsOpen(!isOpen);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const renderMenu = () => (
    <nav>
      <ul>
        <li>
          <Link to="/cognitive-tests" className="sidebar-link">Cognitive Tests</Link>
        </li>
        <li>
          <Link to="/progress-tracking" className="sidebar-link">Progress Tracking</Link>
        </li>
        <li>
          <span className="sidebar-link disabled">Training</span>
        </li>
        <li>
          <span className="sidebar-link disabled">Learn</span>
        </li>
        {isLoggedIn ? (
          <>
            <li>
              <Link to="/profile" className="sidebar-link">Profile</Link>
            </li>
            {isAdmin && (
              <li>
                <Link to="/admin" className="sidebar-link admin-link">Admin</Link>
              </li>
            )}
            <li>
              <button onClick={handleLogout} className="sidebar-link">Logout</button>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/register" className="sidebar-link">Register</Link>
            </li>
            <li>
              <Link to="/login" className="sidebar-link">Login</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );

  return (
    <>
      {!isOpen && (
        <div 
          className="sidebar-trigger"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered && (
            <aside className="sidebar sidebar-floating">
              <button 
                className="sidebar-toggle" 
                onClick={toggleSidebar}
                title="Lock sidebar open"
              >
                &gt;&gt;
              </button>
              {renderMenu()}
            </aside>
          )}
        </div>
      )}
      {isOpen && (
        <aside className="sidebar">
          <button 
            className="sidebar-toggle" 
            onClick={toggleSidebar}
            title="Close sidebar"
            aria-label="Close sidebar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="12 18 6 12 12 6" />
              <polyline points="20 18 14 12 20 6" />
            </svg>
          </button>
          {renderMenu()}
        </aside>
      )}
    </>
  );
};

export default Sidebar;