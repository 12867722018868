import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export const getCogniUser = async (userId) => {
  console.log('Getting user document for UID:', userId);
  const userRef = doc(db, 'users', userId);
  try {
    const userSnap = await getDoc(userRef);
    console.log('User document snapshot:', userSnap);
    if (userSnap.exists()) {
      const userData = { id: userSnap.id, ...userSnap.data() };
      console.log('User data:', userData);
      return userData;
    } else {
      console.log('User document does not exist');
      throw new Error('User not found');
    }
  } catch (error) {
    console.error('Error in getCogniUser:', error);
    throw error;
  }
};

export const createCogniUser = async (userId, userData, role = 'user') => {
  const userRef = doc(db, 'users', userId);
  await setDoc(userRef, {
    ...userData,
    role,
    createdAt: new Date().toISOString(),
    lastLogin: new Date().toISOString(),
    isActive: true
  });
};

export const updateCogniUser = async (userId, userData) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, userData);
};

export const updateLastLogin = async (userId) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    lastLogin: new Date().toISOString()
  });
};
