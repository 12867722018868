export const calculateOverallScore = (trials) => {
  return Math.floor((trials.filter(t => t.isCorrect).length / trials.length) * 100);
};

export const calculateAverageReactionTime = (trials) => {
  return trials.reduce((sum, trial) => sum + trial.reactionTime, 0) / trials.length;
};

export const calculateColorNamingAccuracy = (trials) => {
  const colorTrials = trials.filter(t => t.cueWord === 'Color');
  return colorTrials.filter(t => t.isCorrect).length / colorTrials.length;
};

export const calculateWordReadingAccuracy = (trials) => {
  const wordTrials = trials.filter(t => t.cueWord === 'Word');
  return wordTrials.filter(t => t.isCorrect).length / wordTrials.length;
};

export const calculateInterferenceEffect = (trials) => {
  const colorTrials = trials.filter(t => t.cueWord === 'Color');
  const wordTrials = trials.filter(t => t.cueWord === 'Word');
  const colorAvgRT = colorTrials.reduce((sum, t) => sum + t.reactionTime, 0) / colorTrials.length;
  const wordAvgRT = wordTrials.reduce((sum, t) => sum + t.reactionTime, 0) / wordTrials.length;
  return colorAvgRT - wordAvgRT;
};

export const calculatePercentError = (trials) => {
  const errorCount = trials.filter((trial) => !trial.isCorrect).length;
  return (errorCount / trials.length) * 100;
};
