import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const ErrorLogsView = () => {
  const [errorLogs, setErrorLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchErrorLogs = async () => {
      try {
        const errorLogsRef = collection(db, 'errorLogs');
        const q = query(errorLogsRef, orderBy('timestamp', 'desc'), limit(100));
        const querySnapshot = await getDocs(q);
        const logs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setErrorLogs(logs);
      } catch (error) {
        console.error('Error fetching error logs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchErrorLogs();
  }, []);

  if (loading) return <div>Loading error logs...</div>;

  return (
    <div className="error-logs-view">
      <h3>Error Logs</h3>
      <table>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Error Type</th>
            <th>Error Message</th>
            <th>User ID</th>
          </tr>
        </thead>
        <tbody>
          {errorLogs.map(log => (
            <tr key={log.id}>
              <td>{new Date(log.timestamp).toLocaleString()}</td>
              <td>{log.errorType}</td>
              <td>{log.errorMessage}</td>
              <td>{log.userId || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ErrorLogsView;
