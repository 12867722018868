import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useTestResults } from '../../hooks/useTestResults';
import TestResult from './TestResult';
import ErrorBoundary from '../ErrorBoundary';
import './ProgressTracking.css';

const ProgressTracking = () => {
  const { currentUser } = useAuth();
  const { testResults, expandedResults, error, isLoading, toggleResultDetails } = useTestResults(currentUser?.uid);

  if (isLoading) return <div>Loading test results...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (Object.keys(testResults).length === 0) return <div>No test results available.</div>;

  return (
    <ErrorBoundary>
      <div className="progress-tracking">
        <h2>Progress Tracking</h2>
        {Object.entries(testResults).map(([testType, results]) => (
          <div key={testType} className="test-type-section">
            <h3>{testType} Results</h3>
            <ul className="test-results-list">
              {results.map((result) => (
                <ErrorBoundary key={result.id}>
                  <TestResult
                    result={result}
                    isExpanded={expandedResults[result.id]}
                    onToggle={() => toggleResultDetails(result.id)}
                  />
                </ErrorBoundary>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </ErrorBoundary>
  );
};

export default ProgressTracking;
