import React, { useState, useEffect } from 'react';
import { getStroopTestReport } from '../../services/adminService';

const TestReports = () => {
  const [stroopReport, setStroopReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStroopReport = async () => {
      try {
        console.log('Fetching Stroop test report...');
        const report = await getStroopTestReport();
        console.log('Received report:', report);
        setStroopReport(report);
      } catch (err) {
        console.error('Error fetching Stroop test report:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchStroopReport();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="test-reports">
      <h3>Stroop Test Report</h3>
      {stroopReport ? (
        <table>
          <thead>
            <tr>
              <th>Trial</th>
              <th>Avg. rxn. time (ms)</th>
              <th>Error rate (%)</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(stroopReport).map(([trial, data]) => (
              <tr key={trial}>
                <td>{trial}</td>
                <td>{data.avgReactionTime.toFixed(2)}</td>
                <td>{(data.errorRate * 100).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Stroop test data available.</p>
      )}
    </div>
  );
};

export default TestReports;
