import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: [Current Date]</p>
      
      <section>
        <h2>1. Introduction</h2>
        <p>Welcome to Synaptoscopics. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our cognitive training application.</p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <p>We collect personal information that you provide to us such as name, email address, and usage data. We also collect information automatically when you use the app, including your test results and progress data.</p>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>We use your information to provide and improve our services, communicate with you, and comply with legal obligations. Your test results and progress data are used to personalize your training experience and track your cognitive development.</p>
      </section>

      <section>
        <h2>4. Sharing Your Information</h2>
        <p>We do not sell your personal information. We may share aggregated, non-personal information for research purposes or to improve our services. Your individual data is kept confidential.</p>
      </section>

      <section>
        <h2>5. Data Security</h2>
        <p>We implement security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <p>You have the right to access, correct, or delete your personal information. You can also opt out of certain data collection or use. Contact us to exercise these rights.</p>
      </section>

      <section>
        <h2>7. Changes to This Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      </section>

      <section>
        <h2>8. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at: [Contact Email]</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
