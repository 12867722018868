import React from 'react';

const ResponseButtons = ({ handleKeyPress, activeKey }) => {
  const getButtonClassName = (key) => `key-button ${activeKey === key ? 'key-button-active' : ''}`;

  return (
    <div className="key-button-container">
      <div className={getButtonClassName('r')} onClick={() => handleKeyPress({ key: 'r' })}>R</div>
      <div className={getButtonClassName('g')} onClick={() => handleKeyPress({ key: 'g' })}>G</div>
      <div className={getButtonClassName('b')} onClick={() => handleKeyPress({ key: 'b' })}>B</div>
    </div>
  );
};

export default ResponseButtons;
