import React from 'react';

const TestInstruction = ({ instructions }) => {
  const formattedInstructions = (
    <ol style={{ textAlign: 'left' }}>
      {instructions.map((instruction, index) => (
        <li key={index}>{instruction}</li>
      ))}
    </ol>
  );

  return <div>{formattedInstructions}</div>;
};

export default TestInstruction;
