import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Test.css';

const Test = ({ testName, children }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="test-container">
      {/* <button className="back-button" onClick={handleGoBack}>Back</button> */}
      <h2>{testName}</h2>
      {children}
    </div>
  );
};

export default Test;
