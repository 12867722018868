import React from 'react';

const MemoryTestResults = ({ data }) => (
  <table>
    <thead>
      <tr>
        <th>Metric</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Average Recall</td>
        <td>{data.averageRecall.toFixed(2)}%</td>
      </tr>
      <tr>
        <td>Longest Sequence</td>
        <td>{data.longestSequence}</td>
      </tr>
    </tbody>
  </table>
);

export default MemoryTestResults;
