import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import Test from '../Test';
import TestInstruction from '../TestInstruction';
import StroopTest3 from './StroopTest3/StroopTest';
import testInstructions from '../../../components/config/testInstructions.json';
import TestTypes from '../../../constants/TestTypes';
import useSession from '../../../hooks/useSession';
import MockDataGenerator from './StroopTest3/MockDataGenerator';
import { logError } from '../../../services/errorLoggingService';

const StroopTest = () => {
  console.log('StroopTest component initialized');
  const instructions = testInstructions.stroopTest;
  const [testStarted, setTestStarted] = useState(false);
  const [error, setError] = useState(null);
  const { sessionId, testType } = useSession(TestTypes.STROOP_TEST);
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const [authChecked, setAuthChecked] = useState(false);

  console.log('Initial state:', { testStarted, error, sessionId, testType, currentUser, loading, authChecked });

  useEffect(() => {
    console.log('useEffect triggered');
    console.log('Current user:', currentUser);
    console.log('Loading:', loading);

    if (!loading) {
      if (!currentUser) {
        console.log('No user, attempting to redirect to login');
        navigate('/login');
      } else {
        console.log('User authenticated, setting authChecked to true');
        setAuthChecked(true);
      }
    }
  }, [currentUser, loading, navigate]);

  const startTest = async () => {
    try {
      console.log('startTest function called');
      setTestStarted(true);
    } catch (error) {
      await logError('STROOP_TEST_START_ERROR', error.message, currentUser.uid, { sessionId, testType });
      setError('Failed to start the test. Please try again.');
    }
  };

  console.log('Before render checks');
  console.log('Loading:', loading);
  console.log('AuthChecked:', authChecked);
  console.log('CurrentUser:', currentUser);

  if (loading) {
    console.log('Rendering loading state');
    return <div>Loading...</div>;
  }

  if (!authChecked) {
    console.log('Rendering auth check state');
    return <div>Checking authentication...</div>;
  }

  if (!currentUser) {
    console.log('No current user, rendering null');
    return null;
  }

  console.log('Rendering main StroopTest component');

  return (
    <Test testName="Stroop Test">
      {!testStarted ? (
        <div>
          <TestInstruction instructions={instructions} />
          <button onClick={startTest}>Start Test</button>
          <MockDataGenerator sessionId={sessionId} testType={testType} />
        </div>
      ) : (
        <StroopTest3 sessionId={sessionId} testType={testType} />
      )}
    </Test>
  );
};

export default StroopTest;
