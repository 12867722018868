import React, { useState, useEffect } from 'react';
import StimulusPresentation from './StimulusPresentation';
// import { getAuthCurrentUser } from 'firebase/auth';

function TrialStructure({ phase, trialNumber, onTrialCompletion, onTestComplete, maxTestTrials }) {
  const [cueWord, setCueWord] = useState('');
  const [stimulusWord, setStimulusWord] = useState('');
  const [stimulusColor, setStimulusColor] = useState('');
  const [isInputEnabled, setIsInputEnabled] = useState(true);
  const [trialStartTime, setTrialStartTime] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [displayCue, setDisplayCue] = useState(true);
  const [previousCueWord, setPreviousCueWord] = useState('');

  useEffect(() => {
    const cueWords = ['Color', 'Word'];
    const stimulusWords = ['RED', 'GREEN', 'BLUE'];
    const stimulusColors = ['red', 'green', 'blue'];
  
    // Set the cue word based on the AABBAABB pattern
    const cueIndex = Math.floor(trialNumber / 2) % 2;
    const newCueWord = cueWords[cueIndex];
  
    // Determine if the cue word should be displayed
    const displayCue = newCueWord !== cueWord;
  
    setCueWord(newCueWord);
  
    // Randomly select the stimulus word and color
    const previousWord = stimulusWord;
    const previousColor = stimulusColor;
    let newWord, newColor;
  
    do {
      const wordIndex = Math.floor(Math.random() * stimulusWords.length);
      const colorIndex = Math.floor(Math.random() * stimulusColors.length);
      newWord = stimulusWords[wordIndex];
      newColor = stimulusColors[colorIndex];
    } while (
      (newWord === previousWord || newColor === previousColor) ||
      (newWord === stimulusWords[stimulusColors.indexOf(newColor)])
    );
  
    setStimulusWord(newWord);
    setStimulusColor(newColor);
    setDisplayCue(displayCue);
  }, [trialNumber, cueWord]);
  
  useEffect(() => {
    // Set displayCue to true if the cue word has changed
    setDisplayCue(true);
  }, [cueWord]);

  const handleStimulusPresented = () => {
    setTrialStartTime(Date.now());
  };

  const handleResponse = (response) => {
    const endTime = new Date().toISOString();
    let isCorrect;

    if (cueWord === 'Color') {
      isCorrect = response === stimulusColor[0].toUpperCase();
    } else {
      isCorrect = response === stimulusWord[0];
    }

    const reactionTime = Date.now() - trialStartTime;

    const trialData = {
      cueWord,
      endTime,
      isCorrect,
      isSwitch: trialNumber % 2 === 0,
      phase,
      reactionTime,
      response,
      startTime: new Date(trialStartTime).toISOString(),
      stimulusColor,
      stimulusWord,
      trialNumber
    };

    onTrialCompletion(trialData);

    const feedback = isCorrect ? 'Correct' : 'Wrong';
    setFeedback(feedback);
  };

  const handleContinueTest = () => {
    // Reset the feedback state
    setFeedback('');

    // Proceed to the next trial
    onTrialCompletion();
  };

  const handleTestComplete = () => {
    // Reset the feedback state
    setFeedback('');

    // Notify the parent component that the test is complete
    onTestComplete();
  };

  // const getUserID = () => {
  //   const user = firebase.auth().currentUser;
  //   return user ? user.uid : null;
  // };

  const generateID = () => {
    return `${Math.random().toString(36).substring(2, 15)}-${Date.now()}`;
  };

  return (
    <div className="trial-structure">
      {phase === 'sample' && (
        <div className="sample-trial">
          <StimulusPresentation
            cueWord={cueWord}
            stimulusWord={stimulusWord}
            stimulusColor={stimulusColor}
            onStimulusPresented={handleStimulusPresented}
            onResponse={handleResponse}
            feedback={feedback}
            setFeedback={setFeedback}
            displayCue={displayCue}
            phase={phase}
          />
        </div>
      )}
      {phase === 'practice' && (
        <div className="practice-trial">
          <StimulusPresentation
            cueWord={cueWord}
            stimulusWord={stimulusWord}
            stimulusColor={stimulusColor}
            onStimulusPresented={handleStimulusPresented}
            onResponse={handleResponse}
            feedback={feedback}
            setFeedback={setFeedback}
            displayCue={displayCue}
            phase={phase}
          />
        </div>
      )}
      {phase === 'test' && (
        <div className="test-trial">
          <StimulusPresentation
            cueWord={cueWord}
            stimulusWord={stimulusWord}
            stimulusColor={stimulusColor}
            onStimulusPresented={handleStimulusPresented}
            onResponse={handleResponse}
            feedback={feedback}
            setFeedback={setFeedback}
            displayCue={displayCue}
            phase={phase}
          />
          {(trialNumber + 1) % 40 === 0 && trialNumber !== maxTestTrials - 1 && (
            <div className="rest-break">
              Take a short break if needed. Click the button to continue.
              <button onClick={handleContinueTest}>Continue</button>
            </div>
          )}
          {trialNumber === maxTestTrials - 1 && (
            <div className="test-complete">
              Test phase complete. Click the button to view your results.
              <button onClick={handleTestComplete}>View Results</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TrialStructure;