import React, { useRef, useEffect, useState } from 'react';
import ResponseButtons from './ResponseButtons';

function StimulusPresentation({ cueWord, stimulusWord, stimulusColor, onStimulusPresented, onResponse, feedback, setFeedback, displayCue, phase }) {
  const [showCue, setShowCue] = useState(displayCue);
  const [displayFeedback, setDisplayFeedback] = useState(false);
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const cueTimeoutRef = useRef(null);
  const feedbackTimeoutRef = useRef(null);


  useEffect(() => {
    if (showCue) {
      setIsInputEnabled(false);
      clearTimeout(cueTimeoutRef.current); // Clear existing timeout if any
      cueTimeoutRef.current = setTimeout(() => {
        setShowCue(false);
        setIsInputEnabled(true);
        onStimulusPresented();
      }, 1400);
    } else {
      setIsInputEnabled(true);
      onStimulusPresented();
    }

    return () => clearTimeout(cueTimeoutRef.current);
  }, [showCue, onStimulusPresented]);

  useEffect(() => {
    if (feedback && phase !== 'test') {
      setIsInputEnabled(false);
      setDisplayFeedback(true);
      clearTimeout(feedbackTimeoutRef.current); // Clear existing timeout if any
      feedbackTimeoutRef.current = setTimeout(() => {
        setDisplayFeedback(false);
        setFeedback('');
        setIsInputEnabled(true);
      }, 1399);
    } else {
      setDisplayFeedback(false);
      setIsInputEnabled(true);
    }

    return () => clearTimeout(feedbackTimeoutRef.current);
  }, [feedback, setFeedback, phase]);

  useEffect(() => {
    setShowCue(displayCue);
  }, [displayCue]);

  const handleKeyPress = (event) => {
    if (isInputEnabled) {
      const response = event.key.toUpperCase();
      if (response === 'R' || response === 'G' || response === 'B') {
        onResponse(response);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [isInputEnabled, onResponse]);

  return (
    <div className="stimulus-presentation">
      {displayFeedback && phase !== 'test' ? (
        <div className={`feedback ${feedback === 'Correct' ? 'correct' : 'incorrect'}`}>
          {feedback}
        </div>
      ) : showCue ? (
        <div className="cue-word">{cueWord}</div>
      ) : (
        <div className="stimulus-word" style={{ color: stimulusColor }}>
          {stimulusWord}
        </div>
      )}
      <ResponseButtons />
    </div>
  );
}

export default StimulusPresentation;