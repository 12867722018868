import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import UserManagement from './UserManagement';
import UserDataView from './UserDataView';
import TestResultsView from './TestResultsView';
import TestReports from './TestReports';
import ErrorLogsView from './ErrorLogsView';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const { isAdmin, userRole, currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState('userManagement');

  useEffect(() => {
    console.log('AdminDashboard - isAdmin:', isAdmin);
    console.log('AdminDashboard - userRole:', userRole);
    console.log('AdminDashboard - currentUser:', currentUser);
  }, [isAdmin, userRole, currentUser]);

  if (!isAdmin) {
    return <div className="admin-dashboard unauthorized">You do not have permission to view this page.</div>;
  }

  const renderActiveTab = () => {
    switch(activeTab) {
      case 'userManagement':
        return <UserManagement />;
      case 'userData':
        return <UserDataView />;
      case 'testResults':
        return <TestResultsView />;
      case 'testReports':
        return <TestReports />;
      case 'errorLogs':
        return <ErrorLogsView />;
      default:
        return <UserManagement />;
    }
  };

  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard</h2>
      <div className="admin-tabs">
        <button 
          className={`admin-tab ${activeTab === 'userManagement' ? 'active' : ''}`}
          onClick={() => setActiveTab('userManagement')}
        >
          User Management
        </button>
        <button 
          className={`admin-tab ${activeTab === 'userData' ? 'active' : ''}`}
          onClick={() => setActiveTab('userData')}
        >
          User Data
        </button>
        <button 
          className={`admin-tab ${activeTab === 'testResults' ? 'active' : ''}`}
          onClick={() => setActiveTab('testResults')}
        >
          Test Results
        </button>
        <button 
          className={`admin-tab ${activeTab === 'testReports' ? 'active' : ''}`}
          onClick={() => setActiveTab('testReports')}
        >
          Test Reports
        </button>
        <button 
          className={`admin-tab ${activeTab === 'errorLogs' ? 'active' : ''}`}
          onClick={() => setActiveTab('errorLogs')}
        >
          Error Logs
        </button>
      </div>
      {renderActiveTab()}
    </div>
  );
};

export default AdminDashboard;
