import { useState, useEffect } from 'react';
import { getAllUsers, setUserRole } from '../services/adminService';
import { useAuth } from '../contexts/AuthContext';

export const useUserManagement = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isAdmin } = useAuth();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!isAdmin) {
          throw new Error('You do not have admin privileges');
        }
        const userList = await getAllUsers();
        setUsers(userList);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [isAdmin]);

  const handleRoleChange = async (userId, newRole) => {
    try {
      await setUserRole(userId, newRole);
      const updatedUsers = await getAllUsers();
      setUsers(updatedUsers);
    } catch (err) {
      console.error('Error changing user role:', err);
      setError(err.message);
    }
  };

  return { users, error, loading, handleRoleChange };
};
