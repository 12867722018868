import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

export const logError = async (errorType, errorMessage, userId = null, additionalInfo = {}) => {
  try {
    const errorLog = {
      errorType,
      errorMessage,
      userId,
      timestamp: new Date().toISOString(),
      ...additionalInfo
    };
    await addDoc(collection(db, 'errorLogs'), errorLog);
  } catch (error) {
    console.error('Failed to log error:', error);
  }
};
