import React from 'react';
import StroopTestResult from './StroopTestResult';

const TestResult = ({ result, isExpanded, onToggle }) => {
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  };

  const renderTestDetails = () => {
    switch (result.testType) {
      case 'stroopTest':
        return <StroopTestResult result={result} />;
      // Add cases for other test types here
      default:
        return <div>No details available for this test type.</div>;
    }
  };

  return (
    <li className="test-result-item">
      <div className="test-result-summary">
        <span className="test-score">Score: {result.overallScore}</span>
        <span className="test-date">Date: {formatDate(result.startTime)}</span>
        <button 
          className="toggle-details-btn"
          onClick={onToggle}
        >
          {isExpanded ? 'Hide Details' : 'Show Details'}
        </button>
      </div>
      {isExpanded && renderTestDetails()}
    </li>
  );
};

export default React.memo(TestResult);
