import { useState, useEffect } from 'react';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { getDeviceInfo } from '../utils/deviceInfo';

const useSession = (testType) => {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const createSession = async () => {
      try {
        // Check if a session already exists for this test type
        const sessionsRef = collection(db, 'cogniSessions');
        const q = query(sessionsRef, where('testType', '==', testType));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // If a session exists, use the first one
          setSessionId(querySnapshot.docs[0].id);
        } else {
          // If no session exists, create a new one
          const deviceInfo = await getDeviceInfo();
          const filteredDeviceInfo = Object.fromEntries(
            Object.entries(deviceInfo).filter(([_, v]) => v != null)
          );
          const docRef = await addDoc(sessionsRef, {
            testType,
            startTime: new Date().toISOString(),
            deviceInfo: filteredDeviceInfo,
          });
          setSessionId(docRef.id);
        }
      } catch (error) {
        console.error('Error creating/fetching session:', error);
      }
    };

    if (!sessionId) {
      createSession();
    }
  }, [testType, sessionId]);

  return { sessionId, testType };
};

export default useSession;