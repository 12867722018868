import React, { useState, useEffect } from 'react';
import { initializeTest, addTrialData, writeTestResults } from '../../../../firebase';
import TrialStructure from './TrialStructure';
import DataRecording from './DataRecording';
import useSession from '../../../../hooks/useSession';
import TestTypes from '../../../../constants/TestTypes';
import './StroopTest.css';
import { calculateAverageReactionTime, calculateColorNamingAccuracy, calculateWordReadingAccuracy, calculateInterferenceEffect } from './utils/calculationUtils';

export const MAX_SAMPLE_TRIALS = 8;
export const MAX_PRACTICE_TRIALS = 16;
export const MAX_TEST_TRIALS = 144;

function StroopTest() {
  const { sessionId, testType } = useSession(TestTypes.STROOP_TEST);
  const [phase, setPhase] = useState('intro');
  const [trialNumber, setTrialNumber] = useState(0);
  const [data, setData] = useState([]);
  const [testStartTime, setTestStartTime] = useState(null);
  const [testResultId, setTestResultId] = useState(null);

  const handleStartTest = async () => {
    try {
      const resultId = await initializeTest(sessionId, testType);
      setTestResultId(resultId);
      setTestStartTime(new Date().toISOString());
      setPhase('sample');
    } catch (error) {
      console.error('Error starting test:', error);
    }
  };

  const handlePhaseTransition = () => {
    if (phase === 'sample' && trialNumber + 1 === MAX_SAMPLE_TRIALS) {
      handlePhaseChange('practice');
    } else if (phase === 'practice' && trialNumber + 1 === MAX_PRACTICE_TRIALS) {
      handlePhaseChange('test');
    } else if (phase === 'test' && trialNumber + 1 === MAX_TEST_TRIALS) {
      handleTestComplete();
    } else {
      setTrialNumber(trialNumber + 1);
    }
  };

  const handleTrialCompletion = async (trialData) => {
    console.log('🎉 Trial Complete:', trialData);
    setData([...data, trialData]);
    try {
      await addTrialData(testResultId, trialData);
      handlePhaseTransition();
    } catch (error) {
      console.error('Error adding trial data:', error);
    }
  };

  const handlePhaseChange = (newPhase) => {
    setPhase(newPhase);
    setTrialNumber(0);
  };

  const handleTestComplete = () => {
    console.log('Test phase complete. Calculating and saving results...');
    const testResults = {
      sessionId,
      testType,
      totalTrials: data.length,
      correctTrials: data.filter(trial => trial.isCorrect).length,
      averageReactionTime: calculateAverageReactionTime(data),
      colorNamingAccuracy: calculateColorNamingAccuracy(data),
      wordReadingAccuracy: calculateWordReadingAccuracy(data),
      interferenceEffect: calculateInterferenceEffect(data),
      startTime: testStartTime,
      endTime: new Date().toISOString()
    };
  
    writeTestResults(sessionId, testType, testResults)
      .then(() => {
        console.log('Test results saved successfully');
        setPhase('complete');
      })
      .catch(error => console.error('Error saving test results:', error));
  };

  return (
    <div>
      {phase === 'intro' && (
        <div className="intro-container">
          <button className="start-test-button" onClick={handleStartTest}>Ready ... Set ... Go!</button>
        </div>
      )}
      {phase !== 'intro' && phase !== 'complete' && (
        <TrialStructure
          phase={phase}
          trialNumber={trialNumber}
          onTrialCompletion={handleTrialCompletion}
          onPhaseChange={handlePhaseChange}
          onTestComplete={handleTestComplete}
          maxTestTrials={MAX_TEST_TRIALS}
          maxPracticeTrials={MAX_PRACTICE_TRIALS}
        />
      )}
      {phase === 'complete' && (
        <div>
          <h2>Test Complete</h2>
          <p>Thank you for completing the Stroop Test.</p>
        </div>
      )}
      {/* <DataRecording data={data} /> */}
    </div>
  );
}

export default StroopTest;