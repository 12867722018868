import React, { useEffect } from 'react';
import { calculateAverageReactionTime, calculatePercentError } from './utils/calculationUtils';

function DataRecording({ data }) {
  useEffect(() => {
    // Save data to local storage or send it to a server
    localStorage.setItem('stroopTestData', JSON.stringify(data));
  }, [data]);

  const generateReport = () => {
    const congruentTrials = data.filter((trial) => trial.stimulusWord === trial.stimulusColor);
    const nonCongruentTrials = data.filter((trial) => trial.stimulusWord !== trial.stimulusColor);
    const switchTrials = data.filter((trial) => trial.isSwitch);
    const nonSwitchTrials = data.filter((trial) => !trial.isSwitch);

    const report = {
      congruent: {
        averageReactionTime: calculateAverageReactionTime(congruentTrials),
        percentError: calculatePercentError(congruentTrials),
      },
      nonCongruent: {
        averageReactionTime: calculateAverageReactionTime(nonCongruentTrials),
        percentError: calculatePercentError(nonCongruentTrials),
      },
      switch: {
        averageReactionTime: calculateAverageReactionTime(switchTrials),
        percentError: calculatePercentError(switchTrials),
      },
      nonSwitch: {
        averageReactionTime: calculateAverageReactionTime(nonSwitchTrials),
        percentError: calculatePercentError(nonSwitchTrials),
      },
    };

    return report;
  };

  return (
    <div className="data-recording">
      <strong>Data</strong>
      <pre>{JSON.stringify(generateReport(), null, 2)}</pre>
    </div>
  );
}

export default DataRecording;