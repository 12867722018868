import { useState, useEffect } from 'react';
import { getRecentCogniTestResults } from '../services/cogniTestResultsService';

export const useTestResults = (userId) => {
  const [testResults, setTestResults] = useState({});
  const [expandedResults, setExpandedResults] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTestResults = async () => {
      if (userId) {
        try {
          setIsLoading(true);
          const results = await getRecentCogniTestResults(userId);
          const groupedResults = results.reduce((acc, result) => {
            const testType = result.testType;
            if (!acc[testType]) {
              acc[testType] = [];
            }
            acc[testType].push(result);
            return acc;
          }, {});
          setTestResults(groupedResults);
          
          const initialExpandedState = results.reduce((acc, result) => {
            acc[result.id] = false;
            return acc;
          }, {});
          setExpandedResults(initialExpandedState);
        } catch (error) {
          console.error('Error fetching test results:', error);
          setError('Failed to load test results. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchTestResults();
  }, [userId]);

  const toggleResultDetails = (resultId) => {
    setExpandedResults(prev => ({
      ...prev,
      [resultId]: !prev[resultId]
    }));
  };

  return { testResults, expandedResults, error, isLoading, toggleResultDetails };
};
